const getEnvVar = key => ({ [key]: process.env[`REACT_APP_${key}`] });

const envVars = [
  'API_URL',
  'BASE_URL',
  'IMGIX_URL',
  'ECOM_IMGIX_URL',
  'PUBLIC_URL',
  'GA_ACCESS_CODE',
  'SPREADSHEET_ID',
  'API_KEY',
  'SHEET_ID',
  'CLIENT_EMAIL',
  'PRIVATE_KEY',
  'ROLLBAR_ACCESS',
  'AMPLITUDE_KEY',
  'TAWK_API_KEY',
  'TAWK_CHAT_ID',
  'ROLLBAR_NAME',
  'SENTRY_PUBLIC_KEY',
  'SENTRY_PRIVATE_KEY',
  'ENV',
  'URJA_BASE_URL',
  'URJA_USERNAME',
  'URJA_PASSWORD',
  'INTERNAL_IP',
  'GSCRIPT_WB',
  'GSCRIPT_MH',
  'GSCRIPT_SFURTI'
];

let config = {};

if (process.env.NODE_ENV === 'production') {
  config = envVars.reduce((acc, key) => ({ ...acc, ...getEnvVar(key) }), {});
} else {
  config = envVars.reduce(
    (acc, key) => ({
      ...acc,
      [key]: process.env[`REACT_APP_${key}`]
    }),
    {}
  );
}

export default config;
